<template>
  <div class="pdf-header px-1">
    <div class="row pb-3 report-logo">
      <div class="col-sm-3">
        <img
          src="../../../../static/img/ethos/fis-print-logo.png"
          v-if="partnerSite"
        />
        <img src="/logo.png" v-else />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <h5>
          <b>{{ pageName }}</b>
        </h5>
      </div>
      <div class="col-sm-4 peer-group-select pr-0">
        <Multiselect
          track-by="id"
          label="name"
          v-model="peerGroup"
          :allowEmpty="false"
          :showLabels="false"
          :options="[]"
          class="simple-select analytics-select page-card-select"
        ></Multiselect>
      </div>
      <div class="col-sm-5 d-flex align-items-center justify-content-end">
        <HeaderLogo :logoUrl="logoUrl"></HeaderLogo>
        <div>
          <h5 class="mb-0">
            <b>{{ institutionName }}</b>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// global
import { mapState } from 'vuex'
//ui components
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PDFHeader',
  components: {
    HeaderLogo,
    Multiselect
  },
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    peerGroup: {
      type: Object,
      required: true,
      default() {
        return null
      }
    },
    institutionName: {
      type: String,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped>
.pdf-header {
  display: none;

  .report-logo {
    img {
      width: 11rem;
    }
  }
}

@media print {
  .pdf-header {
    display: block;

    .peer-group-select {
      padding-left: 3rem;
    }
  }
}
</style>
