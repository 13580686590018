<template>
  <b-modal
    id="partnerProductModal"
    ref="partnerProductModal"
    @show="fetchParterProducts"
    size="lg"
    :ok-only="true"
    ok-title="Close"
    no-close-on-esc
  >
    <template slot="modal-header">
      <div class="row pp-modal-title">
        <div class="col-sm-10">
          <h5 class="mb-0">{{ solution.solution }} - Solutions</h5>
        </div>
        <div class="col-sm-2 text-right pr-0">
          <i
            class="cui-cloud-download font-2xl download-icon cursor-pointer"
            title="Download Excel"
            @click="downloadExcel"
            v-if="solution.products"
          ></i>
          <button type="button" class="close" @click="closeModal">x</button>
        </div>
      </div>
    </template>
    <v-wait for="fetchingPartnerProducts">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="7"></content-placeholders-text>
        </content-placeholders>
      </template>

      <div v-if="solution.products">
        <b-table
          hover
          :fields="fields"
          :items="solution.products"
          small
          responsive
          show-empty
          scrollable
        >
          <template v-slot:cell(vendor)="data">
            <div class="d-flex align-items-center">
              <header-logo
                logoType="vendor"
                :logoUrl="vendorLogoUrl(data.item)"
                :logoId="data.item.vendor_id"
                class="justify-content-center"
              ></header-logo>
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(product)="data">
            {{ data.value }}
          </template>
          <template v-slot:empty="scope">
            <div class="no-partner-products my-2">
              No Partners found
            </div>
          </template>
          <template v-slot:cell(website)="data">
            <a
              target="_blank"
              v-if="data.value"
              :href="formatLink(data.value)"
              >{{ data.value }}</a
            >
          </template>
        </b-table>
      </div>
      <div v-else>
        <div class="text=center no-partner-products my-2">
          No Partners Found
        </div>
      </div>
    </v-wait>
  </b-modal>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
import { formatUrl } from '@/utilities'
// ui components
import HeaderLogo from '@/modules/core/components/HeaderLogo'
// ui utils
import downloadsUtil from '@/modules/core/api/downloads'
import Excel from 'exceljs'

export default {
  name: 'PeerGroupModal',
  components: {
    HeaderLogo
  },
  props: {
    solution: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      fields: [
        { key: 'vendor', label: 'Vendor Name', sortable: false },
        { key: 'product', label: 'Product Name', sortable: false },
        { key: 'website', label: 'Website', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters('Advisor', ['reportId', 'spanId', 'selectedPeerGroup']),
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return this.partnerSite ? 'Performance Intelligence' : 'FI Navigator'
    }
  },
  methods: {
    fetchParterProducts() {},
    isBankService(productType) {
      return productType === 'bank_service'
    },
    formatLink(url) {
      return formatUrl(url)
    },
    vendorLogoUrl(item) {
      return `https://finapps-vendor-logos.s3-us-west-2.amazonaws.com/logo_${item.vendor_id}.png`
    },
    downloadExcel() {
      const workbook = new Excel.Workbook()
      const sheet = workbook.addWorksheet('Partner Products')
      sheet.addRow([
        'Copyright ' +
          new Date().getFullYear() +
          " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
      ])
      sheet.addRow()
      sheet.columns = [
        { key: 'vendor', width: 30 },
        { key: 'product', width: 30 },
        { key: 'website', width: 30 }
      ]
      sheet.getRow(3).values = ['Vendor Name', 'Product Name', 'Website']

      sheet.getRow(3).eachCell(cell => {
        cell.style = {
          font: {
            name: 'Calibri',
            bold: true,
            size: 14
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFE4E5E6' }
          },
          border: {
            top: { style: 'double', color: { argb: 'FF525252' } },
            left: { style: 'double', color: { argb: 'FF525252' } },
            bottom: { style: 'double', color: { argb: 'FF525252' } },
            right: { style: 'double', color: { argb: 'FF525252' } }
          }
        }
      })

      this.solution.products.forEach(product => {
        let row = sheet.addRow({
          vendor: product.vendor,
          product: product.product,
          website: product.website
        })

        let webUrl = row.getCell(3)

        webUrl.value = {
          text: product.website,
          hyperlink: this.formatLink(product.website),
          tooltip: this.formatLink(product.website)
        }

        webUrl.font = { color: { argb: '0054AAD5' } }
      })

      downloadsUtil.downloadAsExcel(
        workbook,
        `${this.solution.solution} Solutions`
      )
    },
    closeModal() {
      this.$refs.partnerProductModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
$proxima_regular: Proxima-Nova-Regular, Ubuntu, 'Helvetica Neue', Helvetica,
  Arial, sans-serif;

.no-partner-products {
  font-size: 1.75rem;
  color: #c1c1c1;
  font-family: $proxima_regular;
  text-align: center;
  margin-top: 3rem;
}

.pp-modal-title {
  width: 100%;
}
</style>
