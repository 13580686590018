const reportHelper = {
  methods: {
    greenDotsCount(classValue) {
      switch (true) {
        case classValue < 2:
          return 5
        case classValue < 3:
          return 4
        case classValue < 4:
          return 3
        case classValue < 5:
          return 2
        case classValue < 6:
          return 1
        case classValue >= 6:
          return 0
        default:
          return 0
      }
    },
    toggleAllAcord(action) {
      this.openAll = action === 'open'
    },
    setValue(option, index, type) {
      if (this.selectionTrigger) return
      if (type === 'solution') {
        this.solutionSelect = option
      } else {
        this.bankServiceSelect[index] = option
      }
    },
    setSelectValue() {
      this.selectionTrigger = true
    },
    getOfferingUlChildren(bankServiceChild) {
      let children = []
      if (bankServiceChild.children.length) {
        children = bankServiceChild.children
      } else {
        children.push({
          name: bankServiceChild.name,
          url: bankServiceChild.url,
          has_service: bankServiceChild.has_service,
          bank_service_id: bankServiceChild.bank_service_id,
          peer_group_value: bankServiceChild.peer_group_value
        })
      }
      return children
    },
    getServiceClass(hasService) {
      return hasService ? 'green' : ''
    },
    getProgressClass(peerGroupValue) {
      let className = ''
      if (peerGroupValue <= 5) {
        className = 'justify-content-flex-start'
      }
      return className
    },
    showPeerTable(bankSerice, solutionParentName, solutionChildName) {
      this.peerTableSolutionParent = solutionParentName
      this.peerTableSolutionChild = solutionChildName
      this.selectedBankService = bankSerice
      this.showPeerTableModal = true
    },
    closeModal() {
      this.showPeerTableModal = false
    }
  }
}

export default reportHelper
