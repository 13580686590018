<template>
  <div>
    <div class="">
      <PDFHeader
        :pageName="pageName"
        :peerGroup="peerGroup"
        :institutionName="institutionName"
        :logoUrl="logoUrl"
      ></PDFHeader>
      <b-card
        class="page-card printable-section"
        header-class="page-card-with-logo page-card-header"
        :body-class="cardBodyClasses"
      >
        <div slot="header">
          <b-row class="fit-to-center pl-1">
            <b-col cols="3">
              <div class="page-header-left">
                <div class="pr-1 header-icon">
                  <i class="cuis-sitemap card-icon-font"></i>
                </div>
                <div>
                  <h5 class="mb-0 report-title">
                    <b>
                      {{ pageName }}
                    </b>
                    <span v-if="pageType">
                      - <i>{{ pageType }}</i></span
                    >
                  </h5>
                </div>
              </div>
            </b-col>

            <b-col cols="5">
              <b-row>
                <b-col cols="10" class="pr-1 mt-1">
                  <Multiselect
                    track-by="id"
                    label="name"
                    v-model="peerGroup"
                    :allowEmpty="false"
                    :showLabels="false"
                    :options="peerGroups"
                    class="simple-select analytics-select page-card-select"
                    @open="selectingPeerGroup"
                    @close="peerGroupSelected"
                  ></Multiselect>
                </b-col>
                <b-col cols="1" class="pl-0">
                  <i
                    class="icon-info icons small-line-icon"
                    v-b-modal.st-peers-info
                  ></i>
                </b-col>
                <b-col cols="1" class="pl-0 mb-2" v-if="showAllBanks">
                  <b-button
                    :variant="
                      allBanks ? 'outline-primary' : 'outline-secondary'
                    "
                    :class="[
                      allBanks ? 'font-fix' : 'font-fix-color',
                      'p-0 my-1 px-2 font-weight-bold'
                    ]"
                    @click="fetchForAllBanks"
                    >{{ getAllBanksText }}</b-button
                  >
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="4">
              <div class="page-header-right">
                <header-logo
                  logoType="fi"
                  :logoUrl="logoUrl"
                  :logoId="selectedInstitution.id"
                ></header-logo>
                <div>
                  <h5 class="mb-0">
                    <b>{{ institutionName }}</b>
                  </h5>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <PeerGroupInfoModal></PeerGroupInfoModal>

        <v-wait for="generatingReport">
          <template slot="waiting">
            <ReportPlaceholder
              :progress="reportProgress || 0"
            ></ReportPlaceholder>
          </template>
          <slot name="page_content"></slot>
        </v-wait>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import PDFHeader from '../PDFHeader'
import ReportPlaceholder from '../ReportGeneratorPlaceholder'
import PeerGroupInfoModal from '../PeerGroupInfoModal'

export default {
  name: 'LayoutPageCardWithPeerGroup',
  components: {
    PDFHeader,
    HeaderLogo,
    Multiselect,
    ReportPlaceholder,
    PeerGroupInfoModal
  },
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    pageType: {
      type: String,
      required: false
    },
    institutionName: {
      type: String,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    },
    cardBodyClasses: {
      type: Array,
      required: false
    },
    renderedIn: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      allBanks: false
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'selectedInstitution',
      'selectedPeerGroup',
      'peerGroups',
      'reportId',
      'reportProgress',
      'isSelectedCreditUnion'
    ]),
    peerGroup: {
      get: function() {
        return this.selectedPeerGroup
      },
      set: function(peerGroup) {
        this.updatePeerGroup(peerGroup).then(() => {
          this.$ahoy.track('report_view', {
            fi_id: this.selectedInstitution.id,
            report_id: this.reportId,
            report_type: 'FIN-A'
          })
        })
      }
    },
    showAllBanks() {
      return this.renderedIn && this.renderedIn === 'Top Technologies'
    },
    getAllBanksText() {
      return this.selectedInstitution.fi_type === 'credit_union'
        ? 'All CU'
        : 'All Banks'
    }
  },
  methods: {
    ...mapActions('Advisor', ['getMenuData']),
    ...mapMutations('Advisor', [
      'setPeerGroup',
      'setReportProgress',
      'setSelectingPeerGroup'
    ]),
    updatePeerGroup(peerGroup) {
      this.setPeerGroup({ id: peerGroup.id, name: peerGroup.name })
      if (this.showAllBanks) {
        this.allBanks = false
        this.$emit('peerGroupUpdated')
        return Promise.resolve()
      } else {
        this.setReportProgress({ report_progress: null })
        return this.getMenuData()
      }
    },
    fetchForAllBanks() {
      this.allBanks = !this.allBanks
      this.$emit(this.allBanks ? 'allBanks' : 'peerGroupUpdated')
    },
    selectingPeerGroup() {
      this.setSelectingPeerGroup(true)
    },
    peerGroupSelected() {
      this.setSelectingPeerGroup(false)
    }
  },
  watch: {
    reportProgress: function(newVal) {
      if (!this.showAllBanks) {
        newVal !== 100
          ? this.$wait.start('generatingReport')
          : this.$wait.end('generatingReport')
      }
    }
  }
}
</script>

<style lang="scss">
.page-content {
  padding: 0.4rem 0.3rem;
}

.icon-info {
  cursor: pointer;
}

.strategy-img {
  img {
    width: 100%;
  }
}
.font-fix {
  font-size: 10px;
  color: #5ab2ef;
}
.font-fix-color {
  font-size: 10px;
  color: #9099a6;
}
@media print {
  .printable-section {
    .header-icon {
      display: none !important;
    }

    .page-card-with-logo {
      display: none;
    }
  }
}
</style>
