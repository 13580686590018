<template>
  <b-modal
    size="lg"
    id="merger-history"
    modal-class="merger-history-modal"
    title="Merger History"
    body-class="merger-history-body"
    ok-only
  >
    <div v-if="$apollo.loading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </div>
    <div v-else>
      <b-table
        :items="mergerHistory"
        :fields="fields"
        show-empty
        stacked="md"
        small
        striped
      >
        <template v-slot:cell(dtTrans)="data">
          {{ transactionDate(data.item.dtTrans) }}
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'
import gql from 'graphql-tag'

export default {
  name: 'MergerHistoryModal',
  data() {
    return {
      mergerHistory: []
    }
  },
  computed: {
    ...mapState('Advisor', {
      selectedInstitution: state => state.selectedInstitution
    }),
    fields() {
      return [
        { key: 'institutionName', label: 'Institution' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'mergingType', label: 'Merger Type' },
        { key: 'accounting', label: 'Accounting' },
        { key: 'dtTrans', label: 'Merger Date' }
      ]
    }
  },
  methods: {
    transactionDate(date) {
      let transDate = moment(date, 'YYYYMMDD')
      return transDate._isValid ? transDate.format('MM/DD/YYYY') : 'NA'
    }
  },
  apollo: {
    mergerHistory: {
      query: gql`
        query fiMergerHistories($fiId: Int!) {
          fiMergerHistories(fiId: $fiId) {
            institutionName
            city
            state
            mergingType
            accounting
            dtTrans
          }
        }
      `,
      client:
        window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
          ? 'ethosClient'
          : 'apolloClient',
      variables() {
        return {
          fiId: this.selectedInstitution.id
        }
      },
      update: data => data.fiMergerHistories
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.merger-history-body {
  max-height: 40rem;
  overflow-y: auto;
}
</style>
