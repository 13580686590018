<template>
  <div class="row justify-content-center container--bank-profile">
    <div class="col-sm-12">
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-0 sub-card"
        header-class="px-2 pt-2 pb-0"
      >
        <div slot="header">
          <div class="row ml-0 mr-0">
            <b-col sm="12" md="4" class="pl-2 pr-2">
              <div>
                <h2 class="section--heading text-uppercase">
                  {{ profile_title }}
                </h2>
              </div>
              <h5 class="section--heading-note">What's our short story?</h5>
            </b-col>
            <b-col sm="12" md="6" class="pl-2 pr-3">
              <div>
                <h2 class="section--heading">
                  Offering
                  <i
                    class="icon-info icons small-line-icon"
                    v-b-modal.dt-info
                  ></i>
                </h2>
              </div>
              <h5 class="section--heading-note">Services and Channels</h5>
              <b-modal
                size="lg"
                id="dt-info"
                class="advisor-info-modal"
                title="Offering"
                ok-only
              >
                <div v-if="isOfferingMetric">
                  <p>
                    To assist financial institution executive management in the
                    assessment of their customer offering, {{ siteName }} has
                    developed the industry’s most extensive solution hierarchy.
                    Harnessing big data in an innovative way, {{ siteName }}
                    analyzes every financial institutions’ primary website and
                    other data sources monthly to identify whether the
                    institution offers the solution and, when applicable, the
                    vendor-product utilized.
                  </p>
                  <p>
                    Principally focusing on retail and business solutions, the
                    hierarchy covers nearly 300 solutions ranging from channels
                    to payments to lending products. The hierarchy is evolving
                    rapidly with additions monthly to allow executives to stay
                    abreast of continual innovations in banking’s digital
                    transformation. In addition to retail and business solutions
                    coverage, {{ siteName }} has extended focus to operating,
                    non-customer facing solutions such as core processing. We
                    expect this solution roadmap to continue to evolve rapidly
                    and encourage suggested on individual solutions or
                    categories from our client executives.
                  </p>
                </div>
                <div v-else>
                  <p>
                    {{ siteName }} analyzes every financial institutions’
                    primary website and other data sources to identify its
                    offering of retail services, business services and operating
                    solutions based on a continually evolving solution
                    hierarchy. Through comparison to peers, {{ siteName }} can
                    isolate institution-specific offering opportunities by
                    relative penetration within their peer group. Higher levels
                    of penetration amongst peers for a solution not offered by
                    the institution might be indicative of a competitive
                    disadvantage.
                  </p>
                </div>
              </b-modal>
            </b-col>
            <b-col sm="2" md="2" class="pt-2">
              <span
                class="icon-printer pull-right"
                @click="$emit('print-page')"
              ></span>
            </b-col>
          </div>
        </div>
        <v-wait for="offeringsDataFetch">
          <template slot="waiting">
            <div class="row">
              <b-col sm="12" md="4" class="pl-3 pr-2">
                <div class="sub-card-left rounded show-bank-details">
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-heading />
                    <content-placeholders-text
                      :lines="6"
                    ></content-placeholders-text>
                  </content-placeholders>
                </div>
              </b-col>
              <b-col sm="12" md="8" class="pl-2 pr-3">
                <div class="sub-card-right rounded">
                  <div class="show-offerings" style="height: 15.25rem;">
                    <div class="row offerings">
                      <div class="col-md-6 offerings-col">
                        <div class="header row">
                          <div class="col-md-12">
                            <content-placeholders
                              :rounded="true"
                              class="loader"
                            >
                              <content-placeholders-heading />
                            </content-placeholders>
                          </div>
                        </div>
                        <div class="offerings-content loader-offerings-content">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="5"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="col-md-6 offerings-col">
                        <div class="header row">
                          <div class="col-md-12">
                            <content-placeholders
                              :rounded="true"
                              class="loader"
                            >
                              <content-placeholders-heading />
                            </content-placeholders>
                          </div>
                        </div>
                        <div class="offerings-content loader-offerings-content">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="5"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
          </template>
          <div class="row">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div class="sub-card-left rounded show-bank-details bank-profile">
                <div class="detail-section">
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Name</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.name }}
                    </div>
                  </div>
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">City, St</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.city_st }}
                    </div>
                  </div>
                  <div
                    class="row details-rows"
                    v-if="bankProfile.is_holding_company"
                  >
                    <div class="col-md-4 text-nowrap">Subs</div>
                    <div
                      v-b-modal.sub-structure
                      class="col-md-8 merger-info-link no-focus-highlt"
                    >
                      {{ bankProfile.bhc_subs_count }}
                    </div>
                  </div>
                  <div class="row details-rows" v-if="showBHC(bankProfile)">
                    <div class="col-md-4 text-nowrap">BHC</div>
                    <div
                      v-b-modal.sub-structure
                      class="col-md-8 merger-info-link no-focus-highlt"
                    >
                      {{ bankProfile.holding_company }}
                    </div>
                  </div>
                </div>
                <div class="detail-section">
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Assets</div>
                    <div class="col-md-8 bank-value text-lower">
                      {{ bankProfile.assets }}
                    </div>
                  </div>
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Branches</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.branches }}
                    </div>
                  </div>
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Employees</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.employees }}
                    </div>
                  </div>
                </div>
                <div class="detail-section mb-0">
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Focus</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.focus }}
                      <i
                        class="icon-info icons small-line-icon"
                        v-b-modal.focus-info
                      ></i>
                    </div>
                  </div>
                  <div class="row details-rows">
                    <div class="col-md-4 text-nowrap">Charter</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.bank_charter_class_name }}
                    </div>
                  </div>
                  <div class="row details-rows" v-if="bankProfile.established">
                    <div class="col-md-4 text-nowrap">Established</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.established }}
                    </div>
                  </div>
                  <div class="row details-rows" v-if="!isSelectedCreditUnion">
                    <div class="col-md-4 text-nowrap">Sub-S</div>
                    <div class="col-md-8 bank-value">
                      {{ bankProfile.subchapter_scorp }}
                    </div>
                  </div>
                  <div class="row details-rows" v-if="mergerEnabled">
                    <div class="col-md-4 text-nowrap">Merger History</div>
                    <div class="col-md-8 bank-value">
                      <div
                        v-b-modal.merger-history
                        v-if="bankProfile.merger_restated"
                        class="merger-info-link no-focus-highlt"
                      >
                        YES
                      </div>
                      <div v-else>NO</div>
                    </div>
                  </div>
                </div>
                <BankProfileInfoModal></BankProfileInfoModal>
                <MergerHistoryModal></MergerHistoryModal>
                <SubStructureModal
                  :selectedInstitution="selectedInstitution"
                ></SubStructureModal>
              </div>
            </b-col>

            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="sub-card-right rounded">
                <div class="show-offerings">
                  <div class="row offerings">
                    <div class="col-md-6 offerings-col">
                      <div class="header row">
                        <div class="col-md-7 offerings--section-title">
                          Retail Services
                        </div>
                        <div class="col-md-5">
                          <div class="pull-right">
                            <div class="offering-class-container">
                              <div class="offering-class-text">
                                {{ retailServices.level }}
                              </div>
                              <div class="offering-class-wrapper">
                                <div
                                  class="offering-class"
                                  :class="{
                                    green:
                                      index <
                                      greenDotsCount(retailServices.class_value)
                                  }"
                                  v-for="(count, index) in allLevels"
                                  :key="index"
                                >
                                  {{ index }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="offerings-content">
                        <LiquorTree
                          :data="retailServices.children"
                          :multiple="false"
                          :options="{ paddingLeft: 10 }"
                        >
                          <span
                            class="tree-text"
                            :title="node.text"
                            slot-scope="{ node }"
                            >{{ node.text }}</span
                          >
                        </LiquorTree>
                      </div>
                    </div>
                    <div class="col-md-6 offerings-col">
                      <div class="header row">
                        <div class="col-md-7 offerings--section-title">
                          Business Services
                        </div>
                        <div class="col-md-5">
                          <div class="pull-right">
                            <div class="offering-class-container">
                              <div class="offering-class-text">
                                {{ businessServices.level }}
                              </div>
                              <div class="offering-class-wrapper">
                                <div
                                  class="offering-class"
                                  :class="{
                                    green:
                                      index <
                                      greenDotsCount(
                                        businessServices.class_value
                                      )
                                  }"
                                  v-for="(count, index) in allLevels"
                                  :key="index"
                                >
                                  {{ index }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="offerings-content">
                        <LiquorTree
                          :data="businessServices.children"
                          :multiple="false"
                          :options="{ paddingLeft: 10 }"
                        >
                          <span
                            class="tree-text"
                            :title="node.text"
                            slot-scope="{ node }"
                            >{{ node.text }}</span
                          >
                        </LiquorTree>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </div>
        </v-wait>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LiquorTree from 'liquor-tree'
import BankProfileInfoModal from './BankProfileInfoModal'
import reportHelper from '../mixins/report_helper'
import MergerHistoryModal from './MergerHistoryModal'
import SubStructureModal from './SubStructureModal'

export default {
  name: 'BankProfileAndOffering',
  mixins: [reportHelper],
  props: {
    reportId: {
      type: Number,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  components: {
    LiquorTree,
    BankProfileInfoModal,
    MergerHistoryModal,
    SubStructureModal
  },
  created() {
    this.fetchOfferingsDataFetch()
  },
  data() {
    return {
      bankProfile: {},
      offerings: [{ children: [] }, { children: [] }],
      allLevels: ['EXCELLENT', 'STRONG', 'SOLID', 'MODERATE', 'TRAILING']
    }
  },
  computed: {
    ...mapGetters('Advisor', ['isSelectedCreditUnion', 'selectedInstitution']),

    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return this.partnerSite ? 'Performance Intelligence' : 'FI Navigator'
    },
    retailServices() {
      return this.offerings[0]
    },
    businessServices() {
      return this.offerings[1]
    },
    profile_title() {
      if (this.selectedInstitution.fi_type == 'bhc') {
        return 'BHC Profile'
      } else if (this.selectedInstitution.fi_type == 'credit_union') {
        return 'CU Profile'
      } else {
        return 'Bank Profile'
      }
    },
    isOfferingMetric() {
      return this.reportType === 'Offering'
    },
    mergerEnabled() {
      return this.selectedInstitution.fi_type === 'bank'
    }
  },
  methods: {
    fetchOfferingsDataFetch() {
      this.$wait.start('offeringsDataFetch')
      this.$http
        .get('api/offerings/profile_services', {
          handleErrors: true,
          params: {
            report_id: this.reportId
          }
        })
        .then(res => {
          this.bankProfile = res.data.bank_profile
          if (res.data.bank_services.length) {
            this.offerings = res.data.bank_services
          }
        })
        .then(() => this.$wait.end('offeringsDataFetch'))
    },
    showBHC(bankProfile) {
      return !bankProfile.is_holding_company && !this.isSelectedCreditUnion
    }
  }
}
</script>

<style lang="scss">
.container--bank-profile {
  .tree-arrow.has-child {
    width: 10px;
  }
  span.icon-printer {
    cursor: pointer;
  }
  .tree-content {
    padding: 0px;
  }
  .tree-arrow {
    margin-left: 5px;
    height: 22px;
  }
  .tree-arrow:not(.has-child) {
    margin-left: 15.2px;
  }
  .tree-arrow.has-child:after {
    left: 6px;
    height: 6px;
    width: 6px;
  }
  .tree-node.selected > .tree-content {
    background-color: #fff;
  }
  .detail-section {
    margin-bottom: 0.5rem;
  }
  .tree-anchor {
    margin-left: 2px;
    line-height: 12px;
    padding: 3px 4px;
  }
  .tree-node > .tree-content:hover {
    background: #fff;
  }

  .bank-value {
    color: #6c6d70;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .merger-info-link {
    cursor: pointer;
    color: #20a8d8;
  }

  .show-bank-details {
    &.bank-profile {
      overflow: hidden;
    }
  }
}
</style>
