<template>
  <PageCardWithPeerGroup
    :pageName="reportType + ' Report'"
    :logoUrl="s3LogoUrl"
    :institutionName="selectedInstitution.name || ''"
    :cardBodyClasses="['page-content', 'page-card-body']"
  >
    <template slot="page_content">
      <v-wait for="generatingReport">
        <template slot="waiting">
          <ReportPlaceholder
            :progress="reportProgress || 0"
          ></ReportPlaceholder>
        </template>
        <div class="container--advisor-report">
          <BankProfileAndOffering
            :reportId="reportId"
            @print-page="$emit('print-page')"
            :reportType="reportType"
          ></BankProfileAndOffering>
          <SituationAudit
            :treeId="reportTreeId"
            :reportId="reportId"
            :productId="reportProductId"
            :reportType="reportType"
          ></SituationAudit>
          <ActionPlan
            :treeId="reportTreeId"
            :productId="reportProductId"
            :bankServiceId="reportBankServiceId"
            :metricActionPlan="false"
            :pageName="reportType + ' Report'"
            :showPDFHeader="true"
          ></ActionPlan>
        </div>
      </v-wait>
    </template>
  </PageCardWithPeerGroup>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ReportPlaceholder from './ReportGeneratorPlaceholder'
import PageCardWithPeerGroup from './layouts/PageCardWithPeerGroup'
import BankProfileAndOffering from './BankProfileAndOffering'
import SituationAudit from './SituationAudit'
import ActionPlan from './ActionPlan'

export default {
  name: 'ExecutiveBrief',
  props: {
    reportType: {
      type: String,
      required: true
    },
    reportSubType: {
      type: String,
      required: true
    }
  },
  components: {
    ReportPlaceholder,
    PageCardWithPeerGroup,
    BankProfileAndOffering,
    SituationAudit,
    ActionPlan
  },
  created() {
    this.$wait.start('generatingReport')
    this.getMenuData().then(() => {
      let chosenMetricId = this.metricId(this.reportType, this.reportSubType)
      this.setMetricId(chosenMetricId)

      if (this.reportId && this.reportProgress === 100) {
        this.$wait.end('generatingReport')
      }
    })
  },
  computed: {
    ...mapGetters('Advisor', [
      'selectedInstitution',
      's3LogoUrl',
      'treeId',
      'reportId',
      'productId',
      'metricId',
      'selectedMetricId',
      'reportProgress',
      'bankServiceId'
    ]),
    reportProductId() {
      return this.productId(this.reportType)
    },
    reportBankServiceId() {
      return this.bankServiceId(this.reportType, this.reportSubType)
    },
    reportTreeId() {
      return this.treeId(this.reportType)
    }
  },
  methods: {
    ...mapActions('Advisor', ['getMenuData']),
    ...mapMutations('Advisor', ['setMetricId'])
  },
  watch: {
    reportProgress: function(progress) {
      if (progress === 100) {
        this.$wait.end('generatingReport')
      }
    }
  }
}
</script>

<style lang="scss" src="../../../../scss/_advisor-custom.scss"></style>

<style lang="scss">
.container--bank-profile,
.container--situation-audit {
  .sub-card {
    background-color: #f0f3f5;
    .sub-card-left,
    .sub-card-right {
      border: solid 1px lightgray;
      background-color: white;
    }
  }
  .loader-cnt {
    background-color: white;
  }
  .loader {
    margin: 1rem;
  }
  .offering-class-text {
    text-align: center;
    font-size: 0.5rem;
    color: #6c6d70;
    text-transform: capitalize;
  }
  .offering-class-wrapper {
    line-height: 1;
  }
  .offering-class {
    display: inline-block;
    font-size: 0;
    padding: 0px 0.1rem;
  }
  .offering-class:before {
    background-color: #cfd2d4;
    content: ' ';
    border-radius: 100%;
    width: 0.625rem;
    height: 0.625rem;
    display: inline-block;
  }
  .offering-class.green:before {
    background-color: rgba(141, 196, 74, 0.81);
  }
  .show-offerings {
    .offerings--section-title {
      font-size: 0.8125rem;
      margin-top: auto;
      bottom: -2px;
    }

    background-color: #fff;
    border-radius: 0.25rem;
    padding: 1rem 0.75rem;
    font-size: 0.75rem;
    height: 15.35rem;
    position: relative;
  }
  .offerings-content {
    padding: 0px;
    margin-top: 0.5rem;
    border: 2px solid #f7f5f7;
    border-radius: 0.25rem;
    height: 11.5rem;
    overflow: auto;
  }
  .loader-offerings-content {
    padding: 6px;
    margin-top: 0.8rem;
    height: 8.6rem;
  }
  .show-bank-details {
    background-color: #fff;
    border: 1px solid #cfd2d4;
    border-radius: 0.25rem;
    padding: 1rem 0.75rem;
    font-size: 0.75rem;
    height: 15.47rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  a:hover {
    color: #000;
    text-decoration: none;
  }
}
</style>
