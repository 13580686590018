<template>
  <div id="audit-doughnut-chart" class="audit-chart"></div>
</template>

<script>
// global
import { mapState } from 'vuex'
// ui components
import Highcharts from 'highcharts'

export default {
  name: 'SituationAuditChart',
  props: ['chartData'],
  data() {
    return {}
  },
  mounted() {
    Highcharts.chart('audit-doughnut-chart', {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title: {
        text: `<div class="text-center chart-title">
        <div>PERFORMANCE</div>
        <h5 class="percent">${this.chartData[0]}%</h5>
        <div>EXCEPTION</div>
        </div>`,
        align: 'center',
        verticalAlign: 'middle',
        useHTML: true
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          shadow: false,
          center: ['50%', '50%'],
          size: '120%'
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [
        {
          type: 'pie',
          name: 'Strength',
          innerSize: '66%',
          data: [
            {
              name: 'exception',
              y: this.chartData[0],
              color: this.partnerSite ? '#537CD1' : '#5AB2EF'
            },
            {
              name: 'performance',
              y: this.chartData[1],
              color: '#EDECED'
            }
          ]
        }
      ]
    })
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss">
.audit-chart {
  height: 12.5rem;
}

.chart-title {
  font-size: 0.75rem;
  color: #6c6d70;

  .percent {
    color: #3396b7;
    font-size: 1.75rem;
    line-height: 1;
    padding: 0.25rem 0;
    margin-bottom: 0;
  }
}
</style>
